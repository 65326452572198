@font-face {
    font-family: 'Raleway';
    src: url('./fonts/raleway.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#root {
    background-color: #b5b6c5;
}

.App-link {
    color: #61dafb;
}

.mb-1 {
    margin-bottom: 1rem;
}

.body,body {
    background-color: #333663;
}